<template>
<div>
  
  <!-- <img src="img/banner1.jpg"  style="  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;">
  <br> -->
  <div class="row" >
    <div class="col-md-12">
    <!-- <img src="img/acs_promo.png"  style="  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15%;
  width: 50%;"> -->
  <h2 style="justify-content: center; display:flex;">ACS BLOCKPACK MINT Live</h2>
  <a style="justify-content: center; display:flex;"
   href="https://docs.google.com/presentation/d/e/2PACX-1vR5h3gT3py3Lp8IH-5QyIZF68_k6odyAkpJCISRb1LFEPpVbk50VaRKfyPmqi7S2jmLe4gCPMSHqBJ1/pub?start=false&loop=false&delayms=3000"
   target="0">
  Learn how ACS Blockpacks work</a>
    </div>
     <!-- <div class="col-md-4">
       
     </div> -->
  </div>
      <br>
    <br>
  <base-nfttable :data="table.data"
              :columns="table.columns"
              thead-classes="text-primary" @approve_coin="approve_coin" @mint="mint_nft">
  </base-nfttable>

  <H2>Bounty Rewards - “How it works?”</H2>
      <img src="img/bounty_banner.png"  style="  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;">
  </div>
</template>
<script>
  import { BaseNfttable } from "@/components";

  const mainnet_contracts = require("../../contracts_data.json"); 
  const testnet_contracts = require("../../test_contracts_data.json"); 
  const globals = require("../../globals.json");
  
  let nfts;
  let num_nfts = 4;
  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    nfts = testnet_contracts.nfts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    nfts = mainnet_contracts.nfts
  }

  export default {
    data() {
        return {
        prices:{1:7,
        2:7,
        3:7,
        4:7},
        left:{1:[0,0],
        2:[0,0],
        3:[0,0],
        4:[0,0]},
        single_left:{1:[0,0],
        2:[0,0],
        3:[0,0],
        4:[0,0]},
        single_prices: {1:7,
        2:7,
        3:7,
        4:7,}
        }
    },
    mounted(){ 
      // console.log(nfts)
      for (let i = 1; i < num_nfts+1; i++){
         this.get_price(i)
         this.get_left(i)
         if (i == 3){
          this.get_single_price(i)
         }
         
      }
     
      
    },
    components: {
      BaseNfttable
    },
    computed: {
      table(){
        let data = [];
        for(let nft in nfts){
          console.log(nft, ": ",nfts[nft])
        }

    return {
    "title": "NFTs",
      "columns": [
        "NFT",
        "Price (BNB)"
      ],
    "data": [
            {
        "id": 4,
        "nft": "Exclusive Acryptos Cards",
        "price (bnb)": (((Number(this.prices[4])) + 1e15)/ 1e18).toFixed(4),
        "left": this.left[4],
        "single_price": false,
        "inverse_index": 3
      },
      {
        "id": 3,
        "nft": "Fundamintals W 2022",
        "price (bnb)": (((Number(this.prices[3])) + 1e15)/ 1e18).toFixed(4),
        "left": this.left[3],
        "single_price": (((Number(this.single_prices[3])) + 1e15)/ 1e18).toFixed(4),
        "inverse_index": 2
      },
      {
        "id": 2,
        "nft": "BlockPack Holiday",
        "price (bnb)": (((Number(this.prices[2])) + 1e15)/ 1e18).toFixed(4),
        "left": this.left[2],
        "single_price": false,
        "inverse_index": 1
      },
      {
        "id": 1,
        "nft": "BlockPack E1",
        "price (bnb)": (((Number(this.prices[1])) + 1e15)/ 1e18).toFixed(4),
        "left": this.left[1],
        "single_price": false,
        "inverse_index": 0
      },


    ]
  };
      }
    },
    methods: {
      mint_nft(info){
        console.log("Minting 2!")
        console.log(info)
        this.$emit('mint',info)
      },
      approve_coin(id){
        console.log("approving coin(2)!: ", id)
        this.$emit("approve_coin",id)
      },
      async get_price(id){
        try{
        let the_price = await this.$store.dispatch('get_price',{"nft_id":id})
        this.prices[id] = the_price ;
        }
        catch(err){
          this.prices[id] = "Not Connected" ;
        }
        
      },
      async get_single_price(id){
        try{
        let the_price = await this.$store.dispatch('get_single_price',{"nft_id":id})
        this.single_prices[id] = the_price ;
        }
        catch(err){
          this.single_prices[id] = "Not Connected" ;
        }
        
      },
      async get_left(id){
        try{
        let the_price = await this.$store.dispatch('get_left',{"nft_id":id})
        this.left[id] = the_price ;
        console.log("left got",this.left[id])
        }
        catch(err){
          
          this.left[id] = false ;
        }
        
      }

    }
  }
</script>
<style>
</style>